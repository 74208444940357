.date-picker-container {
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  padding-top: 5px;
}
.date-picker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
}
.date-picker-button {
  border: 0;
  border-radius: 10px;
  color: white;
  background-color: tomato;
  cursor: pointer;
  width: 85px;
}
