.SeasonPicker {
  font-family: Arial, sans-serif;
  text-align: center;
}

h1 {
  color: #333;
}

table {
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
  margin: 10px auto;
}

th,
td {
  border: 1px solid #ddd;
  padding: 1px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #ddd;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: auto;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
  margin-right: 5px;
}

input[type="text"],
input[type="date"] {
  padding: 5px;
  /* margin-bottom: 5px; */
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-right: 5px;
}

.seasonName {
  width: 120px;
}

button {
  padding: 8px;
  background-color: tomato;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 5px;
}

button:hover {
  background-color: #0056b3;
}

.selected-season {
  background-color: #ffecb3;
  font-weight: bold;
  display: inline-flex;
  margin: 5px;
}
